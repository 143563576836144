export default {
  /**
   * @param str {String}
   * @param num {Number}
   * @returns {String}
   *
   * param 'lorem ipsum dolorem asdasd' 10
   * return 'lorem ipsu...'
   */
  methods: {
    truncateString (str, num) {
      if (str && str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }
  }
}
