<template>
  <div class="flip-clock" data-date="2020-12-10" @click="update">
    <tracker
      v-for="(tracker, index) in trackers"
      :property="tracker"
      :time="time"
      :key="index"
    ></tracker>
  </div>
</template>
<script>
import moment from 'moment'
import Tracker from '@/components/Countdown/Tracker'

// Important notes:
// I got the example from https://vuejsexamples.com/flip-clock-and-countdown-with-vue-js/
// https://codepen.io/shshaw/pen/BzObXp

export default {
  props: ['date'],

  data: () => ({
    time: {},
    i: 0,
    trackers: ['days', 'hours', 'minutes', 'seconds'], // 'Random',
    countdown: null
  }),

  components: {
    Tracker
  },

  beforeDestroy () {
    if (window['cancelAnimationFrame']) {
      cancelAnimationFrame(this.frame)
    }
  },

  watch: {
    'date': function (newVal) {
      this.setCountdown(newVal)
    }
  },

  mounted () {
    // console.log('this.date')
    // console.log(this.date)
    if (window['requestAnimationFrame']) {
      this.setCountdown(this.date)
      // this.callback = this.callback || function () {}
      this.update()
    }
  },

  methods: {

    setCountdown (date) {
      if (date) {
        this.countdown = moment(date, 'YYYY-MM-DD HH:mm:ss')
      } else {
        this.countdown = moment().endOf('day') // this.$el.getAttribute('data-date');
      }
    },

    update () {
      // console.log('this.countdown')
      // console.log(this.countdown)
      this.frame = requestAnimationFrame(this.update.bind(this))
      if (this.i++ % 10) { return }
      var t = moment(new Date())
      const time = {}
      // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
      if (this.countdown) {
        t = this.countdown.diff(t)

        // t = this.countdown.diff(t);//.getTime();
        // console.log(t);
        time.days = Math.floor(t / (1000 * 60 * 60 * 24))
        time.hours = Math.floor((t / (1000 * 60 * 60)) % 24)
        time.minutes = Math.floor((t / 1000 / 60) % 60)
        time.seconds = Math.floor((t / 1000) % 60)
      } else {
        time.days = undefined
        time.hours = t.hours() % 13
        time.minutes = t.minutes()
        time.seconds = t.seconds()
      }

      time.Total = t

      // console.log('this.time')
      // console.log(this.time)
      this.$set(this, 'time', time)
      // this.time = time

      // this.$broadcast('time', this.time)
      return this.time
    }
  }
}
</script>

<style lang="less">
.flip-clock {
  height: 80px;
}
.flip-card__top {
  background-color: #444 !important;
}
.flip-card {
  font-size: 2.25rem;
}
.flip-clock__slot {
  font-size: 0.8rem;
}
</style>
